<template>
  <div class="container">
    <el-form :model="ruleForm" :validate-on-rule-change="true" :rules="rules" ref="ruleForm" class="submit-form" label-width="150px" v-loading='loading'>
      <!-- 活动信息 -->
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>活动信息</span>

          <el-form-item label="礼包名称：" prop="Name">
            <el-input v-model="ruleForm.Name" :disabled='editActivityState==2' placeholder="最多输入20个字" maxlength="20" style="width:350px"></el-input>
          </el-form-item>

          <el-form-item label="发放时间：" required >
            <el-form-item prop="IsPermanentActivity" class='date-form-item1'>
              <el-radio :label="true" :disabled='editActivityState==2' v-model="ruleForm.IsPermanentActivity" @change="changeTimeRadio">永久有效</el-radio>
              <br>
              <el-radio :label="false" :disabled='editActivityState==2' v-model="ruleForm.IsPermanentActivity">自定义时间 </el-radio>
            </el-form-item>
            <div class='date-form-item2'>
              <el-form-item  :rules="ruleForm.IsPermanentActivity==false?rules.StartTime:rules.nocheck" prop="StartTime" ref="editStartTime" class="el-form-date-pick" style="margin-left:10px;">
                <el-date-picker format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :disabled="ruleForm.IsPermanentActivity||editActivityState==2||editActivityState==1"
                  v-model="ruleForm.StartTime"
                  type="datetime"
                  placeholder="开始时间"
                  :picker-options='maxOptions'>
                </el-date-picker>
              </el-form-item>
              <span style="margin:0 10px;">~</span>
              <el-form-item :rules="ruleForm.IsPermanentActivity===false?rules.EndTime:rules.nocheck" prop="EndTime" ref="editEndTime" class="el-form-date-pick">
                <el-date-picker format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :disabled="ruleForm.IsPermanentActivity||editActivityState==2"
                  v-model="ruleForm.EndTime"
                  type="datetime"
                  placeholder="结束时间"
                  :picker-options='minOptions'>
                </el-date-picker>
              </el-form-item>
            </div>
          </el-form-item> 
          
          <el-form-item label="礼包库存：" prop="Stock">
            <el-input v-model="ruleForm.Stock"  @input='ruleForm.Stock=validateInt(ruleForm.Stock)' style="width:200px"></el-input>
          </el-form-item>
          <el-form-item label="礼包内容：" :inline-message='true' required>
            <el-form-item>
              <el-checkbox @change="changeIsSendMoney" v-model="ruleForm.IsSendMoney" :disabled='editActivityState==2'>
                <el-form-item prop="SendMoney" ref='editWheelPointsCount' :inline-message='true' class="check-item" :rules="ruleForm.IsSendMoney?rules.SendMoney:rules.nocheck">
                  赠送金
                  <el-input
                    :disabled='editActivityState==2||!ruleForm.IsSendMoney' 
                    v-model="ruleForm.SendMoney" 
                    @input='ruleForm.IsSendMoney=validateFloat(ruleForm.IsSendMoney)'
                    style="margin:0 5px;width:200px;"
                  ></el-input>
                  元
                </el-form-item>
              </el-checkbox>
            </el-form-item>

            <el-form-item>
              <el-checkbox @change="changeIsSendPoint" v-model="ruleForm.IsSendPoint" :disabled='editActivityState==2'>
                <el-form-item prop="SendPoint"
                  ref='editWheelPointsCount'
                  :inline-message='true'
                  class="check-item"
                  :rules="ruleForm.IsSendPoint?rules.SendPoint:rules.nocheck">
                  送
                  <el-input 
                    :disabled='editActivityState==2||!ruleForm.IsSendPoint' 
                    v-model="ruleForm.SendPoint"  
                    @input='ruleForm.SendPoint=validateFloat(ruleForm.SendPoint)' 
                    style="margin:0 5px;width:200px;" 
                  ></el-input>
                  积分
                </el-form-item>
              </el-checkbox>
            </el-form-item>

            <!-- 选优惠券 -->
            <el-form-item>
              <el-checkbox v-model="ruleForm.IsSendCoupon" :disabled='editActivityState==2'>
                送优惠券
              </el-checkbox>
              <span v-show='ruleForm.IsSendCoupon&&editActivityState!=2' style="color:#409EFF;cursor: pointer;margin-left:10px;" @click="handleShowCoupons">选择优惠券</span>
            </el-form-item>
            <div style="color:#909399;">若优惠券数量少于赠送数量，请不再发放，请关注优惠券剩余数量并即使补充</div>
            <div style="color:#F56C56;" class='error-select-coupon' v-if='haveSaveFlag&&ruleForm.IsSendCoupon&&!ruleForm.CouponList.length'>请选择赠送的优惠券</div>
            <!-- 优惠券列表 -->
            <el-form-item style="margin-top:10px;width: 90%;" v-if="ruleForm.IsSendCoupon&&ruleForm.paginationCouponList&&ruleForm.paginationCouponList.length">
              <el-table :data="ruleForm.paginationCouponList">
                <el-table-column :key="1" prop="CouponName" label="优惠券">
                  <template slot-scope="scope">
                    <div style='width:150px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap'>{{scope.row.CouponName}}</div>
                    <span style='color:#F56C56;'>{{scope.row.IsFailure?'已失效':scope.row.LeaveCount< 1? '数量=0': ''}}</span>
                    </template>
                </el-table-column>
                <el-table-column :key="2" prop="UseTypeValue" label="类型"></el-table-column>
                <el-table-column :key="3" prop="CouponContent" label="优惠内容"></el-table-column>
                <el-table-column :key="4" prop="ActivityRangeTypeValue" label="适用商品"></el-table-column>
                <el-table-column :key="5" label="赠送数量/人" width='180px'>
                  <template slot-scope="scope">
                    <el-form-item ref='paginationCouponCount' style="display:inline-block;margin: 15px 0px;;width:160px;" :prop="'paginationCouponList.' +scope.$index + '.Count'"	:rules='rules.couponCount' >
                      <el-input 
                        inline-message 
                        v-model="scope.row.Count" 
                        @input='scope.row.Count=validateInt(scope.row.Count)' 
                        style='width:100px;'
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column :key="6" label="操作"  v-if='editActivityState!=2'>
                  <template slot-scope="scope">
                    <el-button style="color:#F56C6C;" v-if='editType!=4' @click="deleteCoupon(scope.row.Id)" type="text">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination v-if="couponPage.total" style="margin-top:20px;float:right;" @size-change="handleCouSizeChange"
              @current-change="handleCouCurrentChange" :current-page="couponPage.current" :page-sizes="[5,10, 20]" :page-size="couponPage.size"
              layout="total, sizes, prev, pager, next, jumper" :total="couponPage.total">
              </el-pagination>
            </el-form-item>
            <!-- 送赠品 -->
            <el-form-item>
              <el-checkbox v-model="ruleForm.IsSendProduct" :disabled='editActivityState==2'>
                送赠品
              </el-checkbox>
              <span v-if='ruleForm.IsSendProduct&&editActivityState!=2' style="color:#409EFF;cursor: pointer;margin-left:10px;" @click="handleShowPros">选择赠品</span>
            </el-form-item>
            <div style="color:#909399;">若商品库存少于赠送数量，请不再发放，请关注商品库存并即使补充</div>
            <div style="color:#F56C56;" class='error-select-pro' v-if='haveSaveFlag&&ruleForm.IsSendProduct&&!ruleForm.ProductList.length'>请选择赠品</div>
            
            <!-- 赠品列表 -->
            <el-form-item style="margin-top:10px;width: 90%;" v-if="ruleForm.IsSendProduct&&ruleForm.paginationProList&&ruleForm.paginationProList.length">
              <el-table :data="ruleForm.paginationProList"  >
                <el-table-column prop="Name" label="商品" :key="7" width="370">
                  <template slot-scope="scope">
                    <div class="dialog-name-content">
                      <img :src='imgUrl+scope.row.ImgUrl' />
                      <div class="right">
                        <div class="name">
                          <pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
                        </div>
                        <span style='color:#F56C56;'>{{!scope.row.IsOpen ?'仓库中':scope.row.Stock< 1? '已售罄': ''}}</span>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :key="8" prop="Spec" label="规格">
                  <template slot-scope="scope">
                    <span v-if="!scope.row.Spec">默认规格</span>
                    <span v-else>{{scope.row.Spec}}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="Price" :key="9" label="价格"></el-table-column>
                <el-table-column prop="Stock" :key="10" label="库存"></el-table-column>
                <el-table-column :key="11" label="赠送数量/人" width='180px'>
                  <template slot-scope="scope">
                    <el-form-item ref='paginationCount' style="display:inline-block;margin: 15px 0px;;width:160px;" :prop="'paginationProList.' +scope.$index + '.Count'"	:rules='rules.Count' >
                      <el-input 
                        v-model="scope.row.Count" 
                        @input='scope.row.Count= validateInt(scope.row.Count)' 
                        style='width:100px;'
                      ></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column :key="12" label="操作" v-if='editActivityState!=2'>
                  <template slot-scope="scope">
                    <el-button style="color:#F56C6C;" v-if='editType!=4'  @click="deletePro(scope.row.ProductSpecId)" type="text">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
							<el-pagination v-if="proPage.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
							@current-change="handleCurrentChange" :current-page="proPage.current" :page-sizes="[5,10, 20]" :page-size="proPage.size"
							layout="total, sizes, prev, pager, next, jumper" :total="proPage.total">
							</el-pagination>
            </el-form-item>

            <!-- 转盘抽奖-->
            <el-form-item>
              <el-checkbox v-model="ruleForm.IsSendWheelSurf" :disabled='editActivityState==2'>
                转盘抽奖
              </el-checkbox>
              <span v-if='ruleForm.IsSendWheelSurf&&editActivityState!=2' style="color:#409EFF;cursor: pointer;margin-left:10px;" @click="handleShowWheel">选择抽奖活动</span>
            </el-form-item>
            <div style="color:#909399;">每充值成功1次，赠送1次抽奖机会</div>
            <div style="color:#F56C56;" class='error-select-wheel' v-if='haveSaveFlag&&ruleForm.IsSendWheelSurf&&!ruleForm.ActivityWheelSurfList.length'>请选择抽奖活动</div>

            <!-- 转盘抽奖列表-->
            <el-form-item style="margin-top:10px;width: 90%;" v-if="ruleForm.IsSendWheelSurf&&ruleForm.ActivityWheelSurfList&&ruleForm.ActivityWheelSurfList.length">
              <el-table :data="ruleForm.ActivityWheelSurfList" >
                <el-table-column :key="13" prop="ActivityName" width="370" label="活动">
                  <template slot-scope="scope">
                    <div class="name">
                      <pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.ActivityName}}</pre>
                      <span style='color:#F56C56;'>{{scope.row.ActivityStateShow=='已结束'? '已结束': ''}}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :key="14" prop="Spec" label="活动时间">
                  <template slot-scope="scope">
                    <span>{{scope.row.StartTime}}至于{{scope.row.EndTime}}</span>
                  </template>
                </el-table-column>
                <el-table-column :key="15" label="操作"  v-if='editActivityState!=2'>
                  <template slot-scope="scope">
                    <el-button style="color:#F56C6C;" v-if='editType!=4' @click="deleteWheel(scope.row,scope.$index)" type="text">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-form-item>
          </el-form-item>
        </div>
      </el-card>
    </el-form>

    <div class="footer">
      <template v-if='editType==4'>
        <el-button style="width:240px;" @click="handleCancelSubmit">关闭</el-button>
      </template>
      <template v-else>
        <el-button style="width:240px;" @click="handleCancelSubmit">取消</el-button>
        <el-button style="width:240px;" type="primary" @click="handleSubmit('ruleForm')" :loading="loading">保存</el-button>
      </template>
    </div>

		<el-dialog title="选择优惠券" :visible.sync="selectCouponShow" width="1100px" class="dialog">
			<select-coupon
				api="activitycouponpopupList"
				:selectedData="selectedData"
				:params="['KeyWord', 'UseType','','','IsUse']"
				@getSelectList="getSelectCoupons"
        :isUse='true'
				:visible.sync="selectCouponShow"
				v-if="selectCouponShow"
			></select-coupon>
		</el-dialog>
    
    <el-dialog title="选择商品" :visible.sync="selectProShow" width="1100px" class="dialog">
      <select-produce
        :selectedData="selectedData" 
        @getSelectList="getSelectPros" 
        :visible.sync="selectProShow"
        v-if="selectProShow"
      ></select-produce>
    </el-dialog>

		<el-dialog title="选择活动" :visible.sync="selectWheelShow" width="1100px" class="dialog">
      <select-wheel 
        :checkShowId="selectedDataId" 
        :selectActivedata="selectedData"
        @getActivetList="getSelectWheel"
				v-if="selectWheelShow"
      ></select-wheel>
		</el-dialog>
  </div>
</template>
<script>

import zhTitle from '@/lang/zh.js'
import config from '@/config/index'
import apiList from '@/api/other'
import selectCoupon from '@/components/SelectMultipCoupon';
import selectProduce from '@/components/SelectMulProduce/specNoConflict';
import selectWheel from '@/components/SelectSingleWheel';
export default {
  components: {
    selectCoupon,
    selectProduce,
    selectWheel
	},
  data() {
    var checkNo = (rule, value, callback) => {
      return callback();
    };
    var checkStock = (rule, value, callback) => {
      if(!value|| value==''||Number(value) > 100000000 || Number(value) <1){
        return callback(new Error('库存请设置在1~100000000之间，整数'));
      }else {
        return callback();
      }
    };
    var checkSendMoney = (rule, value, callback) => {
      if((!value|| value=='')&&value!==0){
        return callback(new Error('金额请设置在0.01~9999元之间'));
      }else if(Number(value) > 9999 || Number(value) <0.01){
        return callback(new Error('金额请设置在0.01~9999元之间'));
      }else {
        return callback();
      }
    };
    var checkSendPoint = (rule, value, callback) => {

      if((!value|| value=='')&&value!==0){
        return callback(new Error('积分请设置在1~99999之间'));
      }else if(Number(value) > 9999 || Number(value) <0.01){
        return callback(new Error('积分请设置在1~99999之间'));
      }else {
        return callback();
      }
    };
    var checkCouponCount= (rule, value, callback) => {
      let index1 =  rule.fullField.split(".")[1];
      let index = (this.couponPage.current-1)* (this.couponPage.size) + Number(index1)
      let value1 =  this.ruleForm.CouponList[index].Count

      if((!Number(value1)|| Number(value1)=='')&&Number(value1)!==0){
        return callback(new Error('数量请设置在1~99之间，整数'));
      }else if(Number(value1) > 99 || Number(value1) <1){
        return callback(new Error('数量请设置在1~99之间，整数'));
      }else {
        return callback();
      }
    };
    var checkCount= (rule, value, callback) => {
      let index1 =  rule.fullField.split(".")[1];
      let index = (this.proPage.current-1)* (this.proPage.size) + Number(index1)
      let value1 =  this.ruleForm.ProductList[index].Count

      if((!Number(value1)|| Number(value1)=='')&&Number(value1)!==0){
        return callback(new Error('数量请设置在1~99之间，整数'));
      }else if(Number(value1) > 99 || Number(value1) <1){
        return callback(new Error('数量请设置在1~99之间，整数'));
      }else {
        return callback();
      }
    };
    return {
      haveSaveFlag: false,
      imgUrl: config.IMG_BASE,
      loading: false,
      proPage: {
        current: 1,
        total: 0,
        size: 5
      },
      couponPage: {
        total: 0,
        current: 1,
        size: 5
      },
      ruleForm: {
        Name: '',
        IsPermanentActivity: null,
        StartTime: '',
        EndTime: '',
        Stock: null,
        IsSendMoney: false,
        SendMoney: null,
        IsSendPoint: false,
        SendPoint: null,
        IsSendCoupon: false,
        CouponList: [],
        paginationCouponList: [],
        IsSendProduct: false,
        ProductList: [],
        paginationProList: [],
        IsSendWheelSurf: false,
        ActivityWheelSurfList: [],
      },
      rules: {
        IsPermanentActivity: [
          {
            required: true,
            message: "请设置发放时间",
            trigger: "change",
          },
        ],
        Name: [
          {
            required: true,
            message: "请输入礼包名称",
            trigger: "blur",
          },
        ],
        StartTime: [
          {
            required: true,
            message: "请完善开始时间",
            trigger: "blur",
          },
        ],
        EndTime: [
          {
            required: true,
            message: "请完善结束时间",
            trigger: "blur",
          },
        ],
        Stock: [
          {
            required: true,
            trigger: "blur",
            validator: checkStock,
          },
        ],
        SendMoney: [
          {
            required: true,
            trigger: "blur",
            validator: checkSendMoney,
          },
        ],
        SendPoint: [
          {
            required: true,
            trigger: "blur",
            validator: checkSendPoint,
          },
        ],
        nocheck: [
          {
            trigger: "blur",
            validator: checkNo,
          },
        ],
        couponCount: [{
          required: true,
          trigger: 'blur',
          validator: checkCouponCount
        }],
        Count: [{
          required: true,
          trigger: 'blur',
          validator: checkCount
        }],
      },
      
      editType: null,
      editId: null,
      editActivityState: null,

      selectCouponShow: false,
      selectProShow: false,
      selectWheelShow: false,
      selectedData: [],
      selectedDataId: null,
    };
  },
  computed: {
    minOptions: function () {
      let limitTime = this.ruleForm.StartTime;
      let d = new Date();
      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      let currentDate = year + "-" + month + "-" + day + " " + "00:00:00";
      return {
        disabledDate(time) {
          if (limitTime) {
            return time < new Date(new Date(limitTime).toLocaleDateString());
          } else {
            return time < new Date(currentDate);
          }
        },
      };
    },
    maxOptions: function () {
      let limitTime = this.ruleForm.EndTime;
      let d = new Date();
      let day = d.getDate();
      let month = d.getMonth() + 1;
      let year = d.getFullYear();
      let currentDate = year + "-" + month + "-" + day + " " + "00:00:00";
      return {
        disabledDate(time) {
          if (limitTime) {
            return (
              time > new Date(new Date(limitTime).toLocaleDateString()) ||
              time < new Date(currentDate)
            );
          } else {
            return time < new Date(currentDate);
          }
        },
      };
    }
  },
  created() {
    this.editType = this.$route.query.editType
    zhTitle.route.storedPackageEdit =this.editType== 1 ? "添加礼包" : '编辑礼包'
    this.editId = this.$route.query.editId
    this.editActivityState = this.$route.query.editActivityState
    if(this.editType!=1){
       this.getData()
    }
  },
  methods: {
    //商品分页
    paginationPro() {
      this.proPage.total = this.ruleForm.ProductList.length;
      let start = (this.proPage.current - 1) * this.proPage.size
      let end = start + this.proPage.size
      let pagination = this.ruleForm.ProductList.slice(start, end);
      this.ruleForm.paginationProList = pagination
      this.$nextTick(()=>{
        this.$refs.ruleForm.validate();
      })
    },
    handleSizeChange(val) {
      this.proPage.size = val;
      this.paginationPro()
    },
    handleCurrentChange(val) {
      this.proPage.current = val;
      this.paginationPro()
    },
    //商品分页
    paginationCoupon() {
      this.couponPage.total = this.ruleForm.CouponList.length;
      let start = (this.couponPage.current - 1) * this.couponPage.size
      let end = start + this.couponPage.size
      let pagination = this.ruleForm.CouponList.slice(start, end);
      this.ruleForm.paginationCouponList = pagination
      this.$nextTick(()=>{
        this.$refs.ruleForm.validate();
      })
    },
    handleCouSizeChange(val) {
      this.couponPage.size = val;
      this.paginationCoupon()
    },
    handleCouCurrentChange(val) {
      this.couponPage.current = val;
      this.paginationCoupon()
    },
    // 验证整数
    validateInt(num){
      num = num.replace(/^0*(0\.|[1-9])/, '$1');
      num = num.replace(/[^\d]/g, "");
      this.$forceUpdate();
      return  num
    },
    // 两位小数截取
    validateFloat(num){
      if (num != '' && num.substr(0, 1) == '.') {num = ""; }
      num = num.replace(/^0*(0\.|[1-9])/, '$1');
      num = num.replace(/[^\d.]/g, ""); 
      num = num.replace(/\.{2,}/g, ".");
      num = num.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".")
      num = num.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      if (num.indexOf(".") < 0 && num != "") { 
        if (num.substr(0, 1) == '0' && num.length == 2) {
          num = num.substr(1, num.length);
        }
      }
      return num
    },
    async getData(){
      try {
        this.loading= true
        let result = await apiList.activityTopUpGiftInfo({
          Id: this.editId
        }) 
        this.ruleForm = result.Result

        this.ruleForm.Stock = this.ruleForm.Stock==0? null:this.ruleForm.Stock
        this.ruleForm.SendMoney = this.ruleForm.SendMoney==0? null:this.ruleForm.SendMoney
        this.ruleForm.SendPoint = this.ruleForm.SendPoint==0? null:this.ruleForm.SendPoint
        if(this.editType==3){
          this.ruleForm.StartTime = ''
          this.ruleForm.EndTime = ''
        }
        this.paginationPro()
        this.paginationCoupon()
      }catch(e) {
        this.loading= false
      } finally {
        this.loading= false
      }
    },
    changeTimeRadio() {
      this.ruleForm.EndTime = "";
      this.ruleForm.StartTime = "";
      this.$refs["editStartTime"].clearValidate();
      this.$refs["editEndTime"].clearValidate();
    },
    changeIsSendMoney(e) {
      if(!e){
        this.$refs['ruleForm'].clearValidate(['SendMoney']);
      }
    },
    changeIsSendPoint(e) {
      if(!e){
        this.$refs['ruleForm'].clearValidate(['SendPoint']);
      }
    },
    //选择优惠券
    handleShowCoupons() {
      this.selectedData= this.ruleForm.CouponList.map(t=>{
        return {
          ...t
        }
      })
      this.selectCouponShow = true;
    },
    //选择商品
    handleShowPros() {
      this.selectedData= this.ruleForm.ProductList.map(t=>{
        return {
          ...t
        }
      })
      this.selectProShow = true;
    },
    //选择抽奖
    handleShowWheel() {
      this.selectedData= this.ruleForm.ActivityWheelSurfList.map(t=>{
        return {
          ...t,
          Id:t.Id
        }
      })
      this.selectedDataId= this.selectedData.length? this.selectedData[0].Id: null
      this.selectWheelShow = true;
    },
    getSelectCoupons(data) {
      data.forEach(t => {
        t.Count = ''
        t.ActivityCouponId = t.Id
      })
      let couponList = JSON.parse(JSON.stringify(this.ruleForm.CouponList))
      if (this.ruleForm.CouponList) {
        let temp = this.ruleForm.CouponList.map(item => item.Id)
        let resArr = data.filter(item => !temp.includes(item.Id))
        couponList = couponList.concat(resArr)
        this.ruleForm.CouponList = JSON.parse(JSON.stringify(couponList))
      } else {
        this.ruleForm.CouponList = JSON.parse(JSON.stringify(data))
      }
      // 筛选掉没有选择的
      let ids = data.map(t=>{
        return t.Id
      })
      this.ruleForm.CouponList = this.ruleForm.CouponList.filter((t)=>{
        if(ids.indexOf(t.Id)>=0){
          return true
        }
      })
      this.selectCouponShow = false;
      this.paginationCoupon()
    },
    getSelectPros(data) {
      data.forEach(t => {
        t.Count = ''
        t.Id = t.ProductId
        t.ProductSpecId  = t.ProductSpecId 
      })
      let productList = JSON.parse(JSON.stringify(this.ruleForm.ProductList))
      if (this.ruleForm.ProductList) {
        let temp = this.ruleForm.ProductList.map(item => item.ProductSpecId)
        let resArr = data.filter(item => !temp.includes(item.ProductSpecId))
        productList = productList.concat(resArr)
        this.ruleForm.ProductList = JSON.parse(JSON.stringify(productList))
      } else {
        this.ruleForm.ProductList = JSON.parse(JSON.stringify(data))
      }
      // 筛选掉没有选择
      let ids = data.map(t=>{
        return t.ProductSpecId
      })
      this.ruleForm.ProductList = this.ruleForm.ProductList.filter((t)=>{
        if(ids.indexOf(t.ProductSpecId)>=0){
          return true
        }
      })
      this.selectProShow = false;
      this.paginationPro()
    },
    getSelectWheel(data) {
      data.ActivityName = data.Name
      this.ruleForm.ActivityWheelSurfList = []
      this.ruleForm.ActivityWheelSurfList.push(data)
       
      this.selectWheelShow = false;
    },
    deleteCoupon(Id){
      this.ruleForm.CouponList = this.ruleForm.CouponList.filter(t => {
        if (t.Id != Id) {
          return t
        }
      })
      if ((this.ruleForm.CouponList.length) % this.couponPage.size == 0 && this.couponPage.current > 1) {
        this.couponPage.current -= 1;
      }

      this.paginationCoupon()
    },
    deletePro(ProductSpecId){
      this.ruleForm.ProductList = this.ruleForm.ProductList.filter(t => {
        if (t.ProductSpecId != ProductSpecId) {
          return t
        }
      })
      if ((this.ruleForm.ProductList.length) % this.proPage.size == 0 && this.proPage.current > 1) {
        this.proPage.current -= 1;
      }
      this.paginationPro()
    },
    deleteWheel(index){
      this.ruleForm.ActivityWheelSurfList.splice(index,1);
    },
    // 保存
    handleSubmit(ruleForm) {
      this.$refs[ruleForm].validate(async (valid) => {
        if (valid) {
          if(this.ruleForm.IsSendCoupon&&!this.ruleForm.CouponList.length){
            this.haveSaveFlag = true
            this.$common.completeFormInformation("请完善礼包信息", "error-select-coupon");
            return
          }
          if(this.ruleForm.IsSendProduct&&!this.ruleForm.ProductList.length){
            this.haveSaveFlag = true
            this.$common.completeFormInformation("请完善礼包信息", "error-select-pro");
            return
          }
          if(this.ruleForm.IsSendWheelSurf&&!this.ruleForm.ActivityWheelSurfList.length){
            this.haveSaveFlag = true
            this.$common.completeFormInformation("请完善礼包信息", "error-select-wheel");
            return
          }

          if( 
            this.ruleForm.IsSendMoney||
            this.ruleForm.IsSendPoint||
            this.ruleForm.IsSendCoupon||
            this.ruleForm.IsSendProduct||
            this.ruleForm.IsSendWheelSurf
          ){
            this.saveSubmit();
          }else{
            this.$message({
              type: 'error',
              message: '礼包内容至少选择1种',
              showClose: true
            })
          }
        } else {
          this.$common.completeFormInformation("请完善礼包信息", "is-error");
          console.log("error submit!!");
          return false;
        }
      });
    },
    async saveSubmit(){
      
        try{
          this.loading=true
          let data= {
            ...this.ruleForm,
            Id: this.editType==3? null: this.editId
          }
          delete data.paginationProList
          delete data.paginationCouponList
          let result = await apiList.activityTopUpGiftSave(data)
          if(result.IsSuccess){
            this.$message({
              type:'success',
              message:'保存成功',
              showClose:true
            })
            this.$router.push({
              path: '/Customer/storedPackage'
            });
          }

        }catch(e){
          console.log("e",e)
          this.loading=false
        } finally{
          this.loading=false

        }
    },
    // 取消保存
    handleCancelSubmit() {
      this.$router.push({
        path: "/Customer/storedPackage",
      });
    },
  },
};
</script>
<style lang='less' scoped>
.container {
  padding: 10px;
  background-color: #fff;
  .check-item {
    display: inline-block;
    width: 80%;
    margin-bottom: 10px;
  }
  .dialog-name-content {
    display: flex;
    flex-direction: row;
    .name{
      width: 250px;
      overflow:hidden;
      text-overflow:ellipsis;
      display:-webkit-box;
      -webkit-line-clamp:2;
      -webkit-box-orient:vertical;
    }
    span {
      color: #F56C6C;
      font-size: 12px;
    }
		img {
			width: 60px;
			height: 60px;
      flex: 0 0 auto;
			object-fit: contain;
			margin-right: 10px;
		}
  }
  .date-form-item1{
    display:inline-block;
  }
  .date-form-item2{
    display:inline-block;
    .el-form-date-pick {
      display: inline-block;
    }
  }
}

.footer {
    box-shadow: 13px 1px 6px #999;
    line-height: 80px;
    background-color: #fff;
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index :9;
    text-align: center;
}
</style>